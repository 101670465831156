











































































































































































































































































































































import { Component, Watch } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, email, minLength } from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import { IDrivingSchool } from "@/interfaces/IDrivingSchool";
import { TheMask } from "vue-the-mask";
import FscCard from "@/components/Card/FscCard.vue";
import PlzInput from "@/components/Field/PlzInput.vue";
import moment from "moment";
import { IFinanceConfig } from "@/interfaces/IFinanceConfig";
import FscPageHeader from "@/components/FscPageHeader.vue";
import { mixins } from "vue-class-component";
import TabMixin from "@/mixins/TabMixin";
import FscInputVat from "@/components/Field/FscInputVat.vue";
import CompanyMixin from "@/mixins/CompanyMixin";
import { ibanEmpty } from "@/validators/validators.ts";
import FscModal from "@/components/Modal/FscModal.vue";

const DrivingSchool = namespace("driving-school");
const FinanceConfig = namespace("finance-config");
const GocardlessModule = namespace("gocardless");
const LivePayModule = namespace("live-pay");

@Component({
  components: {
    FscInputVat,
    FscPageHeader,
    PlzInput,
    FscCard,
    Validation,
    SaveButton,
    AbortButton,
    FormCard,
    TheMask,
    FscModal,
  },
})
export default class MasterData extends mixins(TabMixin, CompanyMixin) {
  public name = "MasterData";

  public maskNumberFormat = "###-###-#####";
  public drivingSchoolName = "";
  public responsiblePerson = "";
  public website = "";
  public emailAddress = "";
  public postalCode = "";
  public location = "";
  public streetAndNumber = "";
  public federalState = "";
  public incomeTaxOffice = "";
  public incomeTaxNumber = "";
  public vatOffice = "";
  public vatNumber = "";
  public vatId = "";
  public phone = "";

  public accountOwner = "";
  public iban: any = null;
  public bic = "";
  public livePayEmailAddress = "";

  public flashPayAccountOwner = "";
  public flashPayIban: any = null;
  public flashPayBic = "";

  public fibuFixApiClient = "";
  public fibuFixApiSecret = "";
  public fibuFixApiKey = "";

  public accountingGroupPrefix = moment().format("YY");
  public accountingGroup = 0;

  public automaticPaymentModalId = "automatic-payment-modal-id";

  public abibaroApiKey = "";
  public correctAbibaroApiKey = false;

  @DrivingSchool.Action("findOne")
  public findOneAction: any;

  @DrivingSchool.Getter("getDataItem")
  public item: any;

  @DrivingSchool.Action("update")
  public update: any;

  @DrivingSchool.Getter("getSuccess")
  public success: any;

  @DrivingSchool.Getter("getIsLoading")
  public isLoading: any;

  @FinanceConfig.Action("findCurrent")
  public findCurrentAction: any;

  @FinanceConfig.Action("updateCurrent")
  public updateCurrentAction: any;

  @FinanceConfig.Getter("getDataItem")
  public currentFinanceConfig: any;

  @FinanceConfig.Getter("getSuccess")
  public financeSuccess: any;

  @GocardlessModule.Action("drivingSchoolEnabled/findOne")
  public checkDrivingSchoolEnabled: any;

  @GocardlessModule.Getter("drivingSchoolEnabled/getDataItem")
  public drivingSchoolEnabled: any;

  @GocardlessModule.Action("gocardlessLink/findOne")
  public gocardlessGenerateLink: any;

  @GocardlessModule.Getter("gocardlessLink/getDataItem")
  public gocardlessLink: any;

  @LivePayModule.Action("findOne")
  public findAutomaticChargeCurrent: any;

  @LivePayModule.Getter("getDataItem")
  public chargeCurrentResponse: any;

  @LivePayModule.Action("update")
  public livePayPaymentToggle: any;

  @LivePayModule.Getter("getId")
  public toggleResponse: any;

  @Validations()
  public validations(): Record<string, any> {
    return {
      postalCode: { required },
      location: { required },
      accountingGroup: { required },
      emailAddress: { email },
      // vatId: { minValue: minLength(13) },
      iban: { iban: ibanEmpty },
      flashPayIban: { iban: ibanEmpty },
      livePayEmailAddress: { email },
    };
  }

  public onAbort(): void {
    this.closeCurrentTab();
  }

  public async onSubmit(): Promise<void> {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.$invalid) return;

    const data = {
      name: this.drivingSchoolName,
      responsiblePerson: this.responsiblePerson,
      website: this.website,
      emailAddress: this.emailAddress,
      postalCode: this.postalCode,
      location: this.location,
      streetAndNumber: this.streetAndNumber,
      federalState: this.federalState,
      incomeTaxOffice: this.incomeTaxOffice,
      incomeTaxNumber: this.incomeTaxNumber,
      vatOffice: this.vatOffice,
      vatNumber: this.vatNumber,
      vatId: this.vatId,
      phone: this.phone,
      livePayEmailAddress: this.livePayEmailAddress,
    };

    const id = "current";

    await this.update({
      id: id,
      data: { id: 0, ...data },
      resource: "driving-schools",
      descriptionField: "name",
    });

    await this.updateCurrentAction({
      accountOwner: this.accountOwner,
      iban: this.iban ? this.iban : null,
      bic: this.bic,
      fibuFixApiClient: this.fibuFixApiClient,
      fibuFixApiSecret: this.fibuFixApiSecret,
      fibuFixApiKey: this.fibuFixApiKey,
      flashPayAccountOwner: this.flashPayAccountOwner,
      flashPayIban: this.flashPayIban ? this.flashPayIban : null,
      flashPayBic: this.flashPayBic,
      accountingGroup: this.accountingGroup,
      abibaroApiKey: this.abibaroApiKey ? this.abibaroApiKey : null,
    });
    if (this.financeSuccess) {
      await this.findCurrentAction();
    }
  }

  public mounted(): void {
    this.findOneAction({
      resource: "driving-schools",
      id: "current",
      descriptionField: "name",
    });
    this.findCurrentAction();
    this.checkDrivingSchoolEnabledFn();
    this.findAutomaticChargeCurrent({
      resource: "live-pay/driving_school_automatic_charge",
      id: "current",
    });
  }

  public async onToggleAutomaticPayment() {
    await this.livePayPaymentToggle({
      resource: "live-pay/driving_school_automatic_charge",
      id: "current",
    });
    await this.findAutomaticChargeCurrent({
      resource: "live-pay/driving_school_automatic_charge",
      id: "current",
    });
    this.$bvModal.hide(this.automaticPaymentModalId);
  }

  protected cancelAutomaticPaymentToggle() {
    this.$bvModal.hide(this.automaticPaymentModalId);
  }

  protected showAutomaticPaymentToggleModal() {
    this.$bvModal.show(this.automaticPaymentModalId);
  }

  @Watch("item", { immediate: true, deep: true })
  public initDrivingSchool(data: IDrivingSchool): void {
    if (data) {
      this.drivingSchoolName = data.name;
      this.responsiblePerson = data.responsiblePerson;
      this.website = data.website;
      this.emailAddress = data.emailAddress;
      this.postalCode = data.postalCode;
      this.location = data.location;
      this.streetAndNumber = data.streetAndNumber;
      this.federalState = data.federalState;
      this.incomeTaxOffice = data.incomeTaxOffice;
      this.incomeTaxNumber = data.incomeTaxNumber;
      this.vatOffice = data.vatOffice;
      this.vatNumber = data.vatNumber;
      this.vatId = data.vatId;
      this.phone = data.phone;
      this.livePayEmailAddress = data.livePayEmailAddress ? data.livePayEmailAddress : "";
    }
  }

  @Watch("currentFinanceConfig", { immediate: true, deep: true })
  public initFinanceConfig(data: IFinanceConfig): void {
    this.iban = data.iban;
    this.bic = data.bic;
    this.accountOwner = data.accountOwner;
    this.flashPayAccountOwner = data.flashPayAccountOwner;
    this.flashPayIban = data.flashPayIban;
    this.flashPayBic = data.flashPayBic;
    this.accountingGroup = data.accountingGroup;
    this.fibuFixApiClient = data.fibuFixApiClient;
    this.fibuFixApiSecret = data.fibuFixApiSecret;
    this.fibuFixApiKey = data.fibuFixApiKey;
    this.abibaroApiKey = data.abibaroApiKey;
    this.correctAbibaroApiKey = data.correctAbibaroApiKey;
  }

  private checkDrivingSchoolEnabledFn() {
    this.checkDrivingSchoolEnabled({
      resource: "/live-pay/driving_school_enabled/current",
      id: "",
    });
  }

  private async onGenerateLink() {
    await this.gocardlessGenerateLink({
      resource: "/go-cardless-driving-school-customer/generate-access-link/current",
      id: "",
    });

    const url = this.gocardlessLink;
    if (url) window.open(url, "_blank");
  }

  public get drivingSchoolIsEnabled() {
    return this.drivingSchoolEnabled;
  }
}
